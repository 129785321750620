:root{
    --primary:#fff;
}

.btn{
    padding: 8px 20px;
    /* 上下padding 8 左右padding 20 */
    border-radius: 2px;
    outline: none;
    /* border外的框 */
    border:none;
    /* 当我们将鼠标移动到一些元素上时，鼠标的样式会发生相应的改变 */
    cursor:pointer;
}

.btn--primary{
    background-color: var(--primary);
    /* color文本颜色 */
    color: #242424;
    border:1px solid var(--primary);
}

.btn--outline{
    /* background： 可以定义背景的一切内容，如颜色，图片，图片位置等东西
    background-color： 只能定义颜色，别的都不行 */
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition:all 0.3s ease-out;
    /* transition: <property> <duration> <timing-function> <delay>;
    <property>all表示所有可以过渡的属性
    <duration>是过渡的持续时间，
    <timing-function>过渡效果的速度曲线，可以是 
    linear（匀速），ease（慢快慢）
    ease-in（慢开始），ease-out（慢结束），
    ease-in-out（慢开始慢结束）
    <delay> 是过渡效果的延迟时间，以秒或毫秒为单位
    */
}
.btn--medium{
    padding: 8px 20px;
    font-size: 20px;
}
.btn--large{
    padding:12px 26px;
    font-size: 20px;
}
.btn--medium:hover,
.btn--large:hover{
    background: #fff;
    color: #242424;
    transition: all 0.3s ease-out;
}


/* 
定义css变量 --name:value 
一般把所有的变量都写再:root里
root指向这个html的根标签
在里面定义的变量可以在整个doucment中使用 

css的变量有作用域，classA里定义的只能在classA里调用
这就是为什么把变量定义在root中

.classA{
    --name:value
}
.classB{
    color：var(--name)

} 
classA里的在b中用会报错
我们可以这样写
color：var(--name，red)
当name找不到时会默认执行red
*/

video{
    /* 保持其纵横比并填充给定的尺寸。图像将被裁剪以适合 */
    object-fit: cover;
    width: 100%;
    /* 子元素的宽度就等于父元素的宽度 */
    height: 100%;
    position: fixed;
    /* 生成绝对定位的元素，相对于浏览器窗口进行定位 */
    z-index: -1;
}
/* 同一目录引用文件直接写路径,引用下一路径./ 上一路径../ */
.hero-container{
    /* background: url(../../public/images/img-home.jpg) center center/cover no-repeat ; */
    /* background: url('images/img-home.jpg') center center/cover no-repeat; */
    height:100vh;
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    object-fit: contain;
    /* inset向内添加阴影 
    第一个0 x偏移量
    第二个0 y偏移量
    第三个0 阴影模糊半径
    */
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);

}
.hero-container>h1{
    color: #fff;
    font-size: 100px;
    margin-top: -100px;
}

.hero-container>p{
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 
    'Lucida Sans Unicode', 
    'Lucida Grande', 'Lucida Sans', 
    Arial, sans-serif;
}

.hero-btns{
    margin-top: 32px;
}

.hero-btns .btn{
    margin: 6px;
}
.fa-play-circle{
    margin-left: 4px;
}

@media screen and (max-width:960px){
    .hero-container>h1 {
            font-size: 70px;
            margin-top: -150px;
        }
}
@media screen and (max-width:768px) {
    .hero-container>h1 {
        font-size: 50px;
        margin-top: -100px;
    }
    .hero-container>p {         
        font-size: 30px;
    }
    .btn-mobile{
        /* 块元素占一行所以btn会从一行排列到上下排列 */
        display: block;
        text-decoration: none;
    }
    .btn{
        width: 100%;
    }
}